<template>
   <div>
      <!-- PANEL DE CUMPLIMIENTO -->
      <compliance-banner class="mb-4"/>
      
      <template v-if="InvestorRegisteredOnSecondaryMarket">
         <big-title>
            Retirar
         </big-title>
         <withdraw-info-sm />
      </template>
      <template v-else>
         <big-title>
            Retirar <span>fondos</span>.
         </big-title>
         <withdraw-info/>
      </template>


   </div>
</template>

<script>
import WithdrawInfo from "@components/investor/invested/WithdrawInfo";
import WithdrawInfoSm from "@components/investor/invested/WithdrawInfoSm";
import ComplianceBanner from "@components/compliance/ComplianceBanner";

export default {
  components: {WithdrawInfo, WithdrawInfoSm, ComplianceBanner},
   data(){
      return {
         
      }
   }
}
</script>
