<template>
  <div>
    <div v-if="validAccounts.length > 0 && isMounted">
      <div class="vx-row">
        <!-- RETIRO DE SALDO DISPONIBLE -->
        <div v-if="balance > 0" class="vx-col lg:w-1/2 w-full mb-5">
          <vx-card class="mt-4" style="height: 100%;">
            <div class="vx-row">
              <div class="vx-col w-full mb-5">
                <p class="h4-normal mt-4">
                  Saldo disponible <b>{{ costFormat(balance) }}</b>
                </p>
                <p class="mb-6 mt-5"><small>Los retiros que hagas de tu saldo disponible no generan comisión.</small></p>
                <label class="vs-input--label">¿Cuánto deseas retirar? *</label>
                <vs-input ref="balanceAmount" class="w-full" type="text" v-model.lazy="balanceAliasCurrency" v-money="money" name="balanceAmount"
                  v-validate="'required'" :min="0" autocomplete="off" placeholder="0" />
              </div>
            </div>
            <div class="vx-row mt-2">
              <div class="vx-col w-full mb-5">
                <label class="vs-input--label">Cuenta bancaria para retiro *</label>
                <v-select name="account" class="vs-custom"
                  :class="{ 'has-error': hasError('account'), 'is-success': isSuccess('account') }"
                  v-model.lazy="balanceSelectedAccountId" v-validate="requiredRules" :options="accounts"
                  :reduce="item => item.value" :clearable="false" placeholder="Selecciona una opción" :searchable="false">
                </v-select>
                <v-error v-if="hasError('account')" :error="errorText('account')" />
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full mb-5">
                <div class="flex p-2" style="background-color: #f0f0f0;">
                  <feather-icon icon="InfoIcon" svgClasses="w-6 h-6 text-dark" class="mr-1" />
                  <span class="bold mt-1">Los retiros solicitados después del mediodía (hora centro de México) se verán reflejados en máximo un dia hábil, entre las 12:30 y 18:00 hrs. (hora centro de México).</span>
                </div>
              </div>
            </div>
            <div class="vx-row mt-2">
              <div class="vx-col">
                  <vs-button :disabled="balanceAmount <= 0 || balanceSelectedAccountId == null"
                    @click="balanceConfirm()">Retirar</vs-button>
              </div>
            </div>
          </vx-card>
        </div>
        <!-- RETIRO DE INVERSION -->
        <div class="vx-col lg:w-1/2 w-full mb-5">
          <vx-card class="mt-4" style="height: 100%;">
            <div class="vx-row">
              <div class="vx-col w-full mb-5">
                <p class="h4-normal mt-4 mb-6">
                  Inversión efectiva <b>{{ costFormat(effectiveInvestment) }}</b>
                </p>
                <p class="mb-6 mt-5"><small>Los retiros que solicites de tu inversión efectiva generan una comisión del 2% sobre el monto a retirar.</small></p>
                <div class="flex">
                  <label class="vs-input--label">¿Cuánto deseas retirar? *</label>
                  <vs-chip class="ml-2 bold" color="primary">BETA</vs-chip>
                </div>
                <vs-input ref="amount" class="w-full" type="text" v-model.lazy="aliasCurrency" v-money="money" name="amount"
                  v-validate="'required'" :min="0" autocomplete="off" placeholder="0" />
              </div>
            </div>
            <div v-if="effectiveInvestmentUnderLimit" class="vx-row">
              <div class="vx-col w-full mb-5">
                <div class="flex p-5" style="background-color: #FFCCCA;">
                  <feather-icon icon="AlertTriangleIcon" svgClasses="w-8 h-8 text-dark" class="mr-2" />
                  <span class="mt-1">El monto que deseas retirar reduciría tu portafolio por debajo del monto
                    mínimo para operar de $5,000. Si deseas continuar, <b>retiraremos el total de tu inversión.</b></span>
                </div>
              </div>
            </div>
            <div class="vx-row mt-2">
              <div class="vx-col w-full mb-5">
                <label class="vs-input--label">Cuenta bancaria para retiro *</label>
                <v-select name="account" class="vs-custom"
                  :class="{ 'has-error': hasError('account'), 'is-success': isSuccess('account') }"
                  v-model.lazy="selectedAccountId" v-validate="requiredRules" :options="accounts"
                  :reduce="item => item.value" :clearable="false" placeholder="Selecciona una opción" :searchable="false">
                </v-select>
                <v-error v-if="hasError('account')" :error="errorText('account')" />
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full mb-5">
                <div class="flex p-2" style="background-color: #f0f0f0;">
                  <feather-icon icon="InfoIcon" svgClasses="w-6 h-6 text-dark" class="mr-1" />
                  <span class="bold mt-1">Durante este periodo de lanzamiento, tus retiros pueden tomar algunos días en reflejarse.</span>
                </div>
              </div>
            </div>
            <div class="vx-row mt-2">
              <div class="vx-col">
                  <vs-button :disabled="amount <= 0 || selectedAccountId == null"
                    @click="confirm()">Retirar</vs-button>
              </div>
            </div>
          </vx-card>
        </div>
      </div>


    </div>
    <vx-card v-else-if="isMounted" class="mt-4" title="No tienes una cuenta de banco registrada.">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p>
            Por el momento no puedes retirar tu inversión ya que no tienes una cuenta de banco registrada en tu perfil
            de RedGirasol.
            Recuerda que podrás registrar automáticamente una nueva cuenta al abonar fondos, siempre y cuando tú seas
            titular de la misma, ya que
            de lo contrario tus fondos serán reembolsados.<br><br>
            Una vez que se haya registrado automáticamente la cuenta, podrás realizar retiros de manera segura, rápida y
            eficaz.
          </p>
          <vs-button class="mt-5" v-if="showPayfundsButton" @click="goToFunds">Abonar fondos</vs-button>
        </div>
      </div>
    </vx-card>
    <!-- Componente para segundo factor -->
    <two-factor-auth :user-id="UserId"
      custom-indication-message="Por favor ingresa los 6 dígitos de tu Segundo Factor de Autenticación para ejecutar la operación."
      @on-code-validated="onAuth" />

    <!-- Componente para obtener la geolocalización -->
    <rg-geolocator ref="rgGeolocator" />
  </div>
</template>

<script>
import formatHelper from '@mixins/formatHelper';
import currencyHelper from '@mixins/currencyHelper';
import auth2faHelper from "@mixins/auth2faHelper";
import { VMoney } from 'v-money'
import ComplianceBanner from "@components/compliance/ComplianceBanner";
import TwoFactorAuth from "@components/2fa/TwoFactorAuth";
import { BankAccountStatus } from "@/bl/BankAccountStatus";
export default {
  props: {
    showPayfundsButton: { type: Boolean, default: true }
  },
  mixins: [formatHelper, currencyHelper, auth2faHelper],
  directives: { money: VMoney },
  components: { ComplianceBanner, TwoFactorAuth },
  data() {
    return {
      operationForBalance: false,

      aliasCurrency: 0,
      amount: 0,

      balanceAliasCurrency: 0,
      balanceAmount: 0,

      isMounted: false,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$',
        suffix: '',
        precision: 2,
        masked: false
      },

      effectiveInvestment: null,
      balance: null,
      verified: null,
      withdrawalPending:null,
      remaining:null,
      bank: null,
      clabe: null,

      user_person_type: null,
      user_id: null,

      validAccounts: [],
      accounts: [],
      mexicoCountryId: 700,
      selectedAccountId: null,
      balanceSelectedAccountId: null,
      shouldSelectAccount: false,
      requiredRules: 'required',
      factorEnabled: false
    }
  },
  async beforeMount() {
    this.factorEnabled = await this.getUser2FAStatus(this.UserId);
  },
  async mounted() {
    this.isMounted = false
    this.user_person_type = parseInt(this.UserPersonType);
    this.user_id = parseInt(this.UserId);
    await this.getWithdrawalInfo();
    await this.getUserAccounts();
    this.isMounted = true
  },
  watch: {
    aliasCurrency: function (nval, oldval) {
      this.amount = this.unMoneyFormat(nval);
    },
    balanceAliasCurrency: function (nval, oldval) {
      this.balanceAmount = this.unMoneyFormat(nval);
    },
  },
  computed: {
    availableMoney() {
      return this.costFormat(this.effectiveInvestment+this.balance);
    },
    withdrawalAmountIncludingCommission() {
      return this.realWithdrawalAmount+this.withdrawalCommissionAmount;
    },
    realWithdrawalAmount() {
      return this.amount;
    },
    withdrawalCommissionAmount() {
      return (this.amount*.02)*1.16;
    },
    remainingEffectiveInvestment() {
      return this.effectiveInvestment - this.withdrawalAmountIncludingCommission;
    },
    effectiveInvestmentUnderLimit() {
      return this.remainingEffectiveInvestment < 5000;
    },
    withdrawInvestmentClassColl() {
      return this.balance > 0? 'vx-col lg:w-1/2 w-full mb-5':'vx-col w-full mb-5';
    }
  },
  methods: {
    hasError(val) {
      return this.errors.has(val);
    },
    errorText(val) {
      return this.errors.first(val);
    },
    isSuccess(val) {
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },
    async getWithdrawalInfo() {
      this.showLoading(true);
      try {
        let response = await axios.get(`api/v2/investor/${this.InvestorId}/get-info-withdrawal-sm`);
        this.effectiveInvestment = response.data.effective_investment
        this.balance = response.data.balance
        this.verified = response.data.verified
        this.withdrawalPending = response.data.withdrawal_pending
        this.remaining = response.data.remaining
      }
      catch (e) {
      }
      this.showLoading(false);
    },
    async getUserAccounts() {
      this.showLoading(true);
      try {
        const res = await axios.get(`api/v1/userBankAccounts/${this.UserId}`);
        const accs = res.data;
        let formattedAccounts = [];
        accs.forEach(f => {
          let nacc = this.getFormattedAccount(f);
          formattedAccounts.push(nacc);
        });

        this.validAccounts = formattedAccounts.filter(f => f.is_verified);
        this.validAccounts.sort(function (a, b) {
          if (a.is_default) {
            return -1
          }
          else if (b.is_default) {
            return 1;
          }
          return 0;
        });

        this.validAccounts.forEach(opt => {
          this.accounts.push({ value: parseInt(opt.id), label: `${opt.bank_name} ${opt.clabe}` }); //conversion a sintaxis de vx-select
        })

        const defaultAccount = this.validAccounts.find(f => f.is_default);
        if (defaultAccount != null) {
          this.selectedAccountId = defaultAccount.id;
          this.balanceSelectedAccountId = defaultAccount.id;
        }
      }
      catch (e) {
      }
      this.showLoading(false);
    },
    getFormattedAccount(f) {
      let nacc = {
        id: f.id,
        bank_id: f.bank_id,
        is_default: f.is_default === 1,
        is_verified: f.verified === BankAccountStatus.VERIFIED,
      }
      if (f.bank && f.bank.country_id === this.mexicoCountryId) {
        nacc.bank_name = f.bank.short_name;
        nacc.clabe = '****'+this.visibleOnlyLastNChars(f.clabe, 4);
      }
      else {
        nacc.bank_name = f.bank.name;
        nacc.clabe = '****'+this.visibleOnlyLastNChars(f.bic_swift, 4);
      }
      return nacc;
    },
    async confirm() {
      if (!this.validateWithdrawalAmount()) {
        return
      }

      if (!this.validateAccount()) {
        return;
      }

      if (!this.factorEnabled) {
        this.$vs.dialog({
          color: "warning",
          type: "confirm",
          title: "Autenticación necesaria",
          text: "Para realizar esta operación es necesario habilitar tu Segundo Factor de Autenticación.",
          acceptText: "Habilitar",
          cancelText: 'Cancelar',
          accept: this.goTo2FaPage,
        });
        return;
      }
      this.operationForBalance=false;
      let acc = this.validAccounts.find(f => f.id === this.selectedAccountId);
      let effectiveInvestmentAfterWithdrawal = 0;
      let text = '';
      if (!this.effectiveInvestmentUnderLimit) {
        effectiveInvestmentAfterWithdrawal = this.remainingEffectiveInvestment;
        text = `Monto a recibir: ${this.costFormat(this.realWithdrawalAmount)}\n\n`;
        text += `Comisión (2%) + IVA: ${ this.costFormat(this.withdrawalCommissionAmount)}\n\n`;
        text += `Total a retirar: ${this.costFormat(this.withdrawalAmountIncludingCommission)}\n\n`;
      } else {
        let newCommission = this.effectiveInvestment*.02;
        let realAmount = this.effectiveInvestment-newCommission;
        text = `Monto a recibir: ${this.costFormat(realAmount)}\n\n`;
        text += `Comisión (2%) + IVA: ${ this.costFormat(newCommission)}\n\n`;
        text += `Total a retirar: ${this.costFormat(this.effectiveInvestment)}\n\n`;
      }
      text += `Cuenta destino: ${acc.bank_name} ${acc.clabe}\n\n\n`;
      text += `Tu inversión después del retiro será de: ${this.costFormat(effectiveInvestmentAfterWithdrawal)}`;

      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: "Resumen",
        text: text,
        acceptText: "Aceptar",
        cancelText: "Cancelar",
        accept: this.start2faValidation
      });
    },
    validateWithdrawalAmount() {
      let a = this.withdrawalAmountIncludingCommission;
      if (a < 0) {
        this.errorNotifDialog('Retiro inválido', 'No puedes retirar menos de $0.00')
        return false
      }

      if (a === 0) {
        this.errorNotifDialog('Retiro inválido', 'El monto de retiro es inválido')
        return false
      }

      if (a > this.remaining) {
        this.errorNotifDialog('Retiro inválido', 'El día de hoy puedes retirar hasta ' + this.costFormat(this.getAmountWithNoCommissionTax(this.remaining)));
        return false
      }

      if (a > this.effectiveInvestment) {
        this.errorNotifDialog('Retiro inválido', 'No puedes retirar un monto mayor a tu portafolio actual.');
        return false
      }
      return true;
    },
    validateAccount() {
      if (this.selectedAccountId == null) {
        this.errorNotifDialog('Cuenta bancaria requerida', 'Es necesario que indiques la cuenta bancaria a la que se hará el retiro.')
        return false
      }
      return true
    },

    async start2faValidation() {
      // se pide la geolocalizacion para la operacion
      this.$refs.rgGeolocator.askForGeolocation();
      this.$store.commit("TOGGLE_IS_2FA_POPUP_ACTIVE", true);
    },
    async onAuth(code) {
      if (this.operationForBalance) {
        await this.doExecuteBalanceWithdraw(code);        
      } else {
        await this.doExecuteWithdraw(code);
      }
    },
    async doExecuteWithdraw(code) {
      let a = this.withdrawalAmountIncludingCommission;
      if (this.effectiveInvestmentUnderLimit) {
        a=this.effectiveInvestment;
      }
      let data = {
        amount: a,
        bank_account_id: this.selectedAccountId,
        code: code,
        geolocation: this.CurrentGeolocationData
      }
      this.injectAccountMetadataToPayload(data);

      this.showLoading(true)
      try {
        const response = await axios.post('/api/v2/investor/execute-withdrawal-sm', data);
        this.effectiveInvestment = response.data.effective_investment;
        this.aliasCurrency = null;
        this.successNotifDialog('Retiro programado exitosamente', response.data.message);
        this.$emit('on-saved');
        await this.getWithdrawalInfo();
        await this.onInvestorGeneralDataUpdated();
      }
      catch (error) {
        if (typeof error.response.data.message !== 'undefined') {
          this.errorNotifDialog('Retiro fallido', error.response.data.message);
        } else {
          this.failedOperationNotif();
        }
      }
      this.showLoading(false)
    },
    async balanceConfirm() {
      if (!this.validateWithdrawalBalanceAmount()) {
        return
      }
      if (!this.validateBalanceAccount()) {
        return;
      }
      if (!this.factorEnabled) {
        this.$vs.dialog({
          color: "warning",
          type: "confirm",
          title: "Autenticación necesaria",
          text: "Para realizar esta operación es necesario habilitar tu Segundo Factor de Autenticación.",
          acceptText: "Habilitar",
          cancelText: 'Cancelar',
          accept: this.goTo2FaPage,
        });
        return;
      }

      this.operationForBalance=true;
      let acc = this.validAccounts.find(f => f.id === this.balanceSelectedAccountId);
      let text = '';
      text = `Monto a retirar: ${this.balanceAliasCurrency}\n\n`;
      text += `Cuenta destino: ${acc.bank_name} ${acc.clabe}`;
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: "Resumen",
        text: text,
        acceptText: "Aceptar",
        cancelText: "Cancelar",
        accept: this.start2faValidation
      });
    },
    validateWithdrawalBalanceAmount() {
      let a = this.balanceAmount;
      if (a < 0) {
        this.errorNotifDialog('Retiro inválido', 'No puedes retirar menos de $0.00')
        return false
      }

      if (a === 0) {
        this.errorNotifDialog('Retiro inválido', 'El monto de retiro es inválido')
        return false
      }

      if (a > this.balance) {
        this.errorNotifDialog('Retiro inválido', 'No puedes retirar un monto mayor a tu saldo disponible.');
        return false
      }
      return true;
    },
    validateBalanceAccount() {
      if (this.balanceSelectedAccountId == null) {
        this.errorNotifDialog('Cuenta bancaria requerida', 'Es necesario que indiques la cuenta bancaria a la que se hará el retiro.')
        return false
      }
      return true
    },
    async doExecuteBalanceWithdraw(code) {
      let a = this.balanceAmount;
      let data = {
        amount: a,
        bank_account_id: this.balanceSelectedAccountId,
        code: code,
        geolocation: this.CurrentGeolocationData
      }
      this.injectAccountMetadataToPayload(data);
      this.showLoading(true)
      try {
        const response = await axios.post('/api/inversionista/bank', data);
        this.balance = response.data.balance;
        this.balanceAliasCurrency = null;
        this.successNotifDialog('Retiro programado exitosamente', response.data.message);
        this.$emit('on-saved');
        await this.onInvestorGeneralDataUpdated();
        await this.getWithdrawalInfo();
      }
      catch (error) {
        if (typeof error.response.data.message !== 'undefined') {
          this.errorNotifDialog('Retiro fallido', error.response.data.message);
        } else {
          this.failedOperationNotif();
        }
      }
      this.showLoading(false)
    },
    async goTo2FaPage() {
      this.$emit('on-closed');
      await this.$router.push({ name: 'auth2FaInversionista' });
    },
    async goToProfile() {
    },
    async goToFunds() {
      this.$emit('on-closed');
      await this.$router.push({ name: 'abonarFondos' });
    },
  }
}
</script>

<style>
div.vs-dialog-danger {
  z-index: 100010 !important;
}

div.vs-dialog-warning {
  z-index: 100010 !important;
}

div.vs-dialog-success {
  z-index: 100010 !important;
}
</style>